import styled from 'styled-components/macro'
import * as Yup from 'yup'

import AdminAddressUpdater from '../../components/AdminUpdaters/AdminAddressUpdater'
import NumaricValueUpdater from '../../components/AdminUpdaters/NumaricValueUpdater'
import { EscrowSellOwnerValidator } from '../../components/AuthValidator'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { useEscrowSellContract } from '../../hooks/useContract'
import { ThemedText } from '../../theme'

const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  background: #fff;
  padding: 16px;
  border-radius: 30px;
`
export default function EscrowSellManager() {
  const escrowContract = useEscrowSellContract()

  return (
    <Container>
      <EscrowSellOwnerValidator>
        <AutoColumn gap="30px">
          <ThemedText.LargeHeader textAlign={'center'}>Manage Escrow Sell</ThemedText.LargeHeader>

          {escrowContract && (
            <>
              <AutoColumn gap="30px">
                <AutoRow gap={'20px'}>
                  <AdminAddressUpdater
                    title="Update Escrow Manager Address"
                    readFun="escrowManager"
                    writeFun="updateEscrowManager"
                    trxSummary="Updated Escrow Manager Address"
                    contract={escrowContract}
                  />
                  <AdminAddressUpdater
                    title="Update Admin Address"
                    readFun="admin"
                    writeFun="updateAdmin"
                    trxSummary="Updated Admin Address"
                    contract={escrowContract}
                  />
                  <AdminAddressUpdater
                    title="Update Burn Address"
                    readFun="burnAddress"
                    writeFun="updateBurnAddress"
                    trxSummary="Updated Burn Address"
                    contract={escrowContract}
                  />
                  <NumaricValueUpdater
                    title="Update Sell Fee Percentage"
                    readFun="feePercentage"
                    writeFun="updateFeePercentage"
                    trxSummary="Updated Fee Percentage"
                    contract={escrowContract}
                    decimals={3}
                    schema={Yup.object().shape({
                      value: Yup.number().required().integer('Must be a positive integer').max(100),
                    })}
                    endEndorse={<ThemedText.Main>%</ThemedText.Main>}
                  />
                </AutoRow>

                <AutoRow gap={'20px'}>
                  <NumaricValueUpdater
                    title="Update Sell Price"
                    readFun="price"
                    writeFun="updatePrice"
                    trxSummary="Updated Price"
                    contract={escrowContract}
                    decimals={18}
                    schema={Yup.object().shape({
                      value: Yup.number().required().positive('Must be a positive number'),
                    })}
                  />
                  <NumaricValueUpdater
                    title="Update Escrow Time"
                    readFun="escrowBlocks"
                    writeFun="updateEscrowBlocks"
                    trxSummary="Updated Escrow Blocks"
                    contract={escrowContract}
                    decimals={0}
                    schema={Yup.object().shape({
                      value: Yup.number()
                        .required('Required')
                        .positive('Must be positive')
                        .integer('Must be an integer')
                        .min(1, 'Must be at least 1'),
                    })}
                    resultTransformer={(value: string) => Math.round((Number(value) / 60) * 12).toString()}
                    transformer={(value: string) => Math.round((Number(value) * 60) / 12).toString()}
                    endEndorse={<ThemedText.Main>Minutes</ThemedText.Main>}
                  />
                </AutoRow>
              </AutoColumn>
            </>
          )}
        </AutoColumn>
      </EscrowSellOwnerValidator>
    </Container>
  )
}
