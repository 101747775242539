import AdminAddressUpdater from 'components/AdminUpdaters/AdminAddressUpdater'
import NumaricValueUpdater from 'components/AdminUpdaters/NumaricValueUpdater'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import * as Yup from 'yup'

import { EscrowBuyOwnerValidator } from '../../components/AuthValidator'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { useEscrowContract } from '../../hooks/useContract'

const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  background: #fff;
  padding: 16px;
  border-radius: 30px;
`
export default function EscrowBuyManager() {
  const escrowContract = useEscrowContract()

  return (
    <Container>
      <EscrowBuyOwnerValidator>
        <>
          <AutoColumn gap="30px">
            <ThemedText.LargeHeader textAlign={'center'}>Manage Escrow Buy</ThemedText.LargeHeader>

            {escrowContract && (
              <>
                <AutoColumn gap="30px">
                  <AutoRow gap={'20px'}>
                    <AdminAddressUpdater
                      title="Update Escrow Manager Address"
                      readFun="admin"
                      writeFun="updateAdmin"
                      trxSummary="Updated Escrow Manager Address"
                      contract={escrowContract}
                    />
                    <AdminAddressUpdater
                      title="Update Admin Address"
                      readFun="feeReceiver"
                      writeFun="updateFeeReceiver"
                      trxSummary="Updated Admin Address"
                      contract={escrowContract}
                    />
                  </AutoRow>
                  <AutoRow gap={'20px'}>
                    <NumaricValueUpdater
                      title="Update Fee Percentage"
                      readFun="feePercentage"
                      writeFun="updateFeePercentage"
                      trxSummary="Updated Fee Percentage"
                      contract={escrowContract}
                      decimals={3}
                      schema={Yup.object().shape({
                        value: Yup.number().required().integer('Must be a positive integer').max(100),
                      })}
                      endEndorse={<ThemedText.Main>%</ThemedText.Main>}
                    />
                    <NumaricValueUpdater
                      title="Update Price"
                      readFun="price"
                      writeFun="updatePrice"
                      trxSummary="Updated Price"
                      contract={escrowContract}
                      decimals={18}
                      schema={Yup.object().shape({
                        value: Yup.number().required().positive('Must be a positive number'),
                      })}
                    />
                  </AutoRow>
                </AutoColumn>
              </>
            )}
          </AutoColumn>
        </>
      </EscrowBuyOwnerValidator>
    </Container>
  )
}
