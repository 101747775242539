import { Contract } from '@ethersproject/contracts'
import { getAddress } from 'ethers/lib/utils'
import { useMemo } from 'react'

import { useSingleCallResult } from '../state/multicall/hooks'
import { useActiveWeb3React } from './web3'

export function useAuthValidator(
  funName: string,
  contract: Contract | null
): { valid: boolean; requiredAddress?: string } {
  const { account } = useActiveWeb3React()
  const result = useSingleCallResult(contract, funName)
  return useMemo(() => {
    return result.result?.[0] && account
      ? {
          valid: getAddress(result?.result?.[0]) === getAddress(account),
          requiredAddress: getAddress(result?.result?.[0]),
        }
      : { valid: false }
  }, [result, account])
}

export function useAuthRoleValidator(role: string, contract: Contract | null): { valid: boolean } {
  const { account } = useActiveWeb3React()
  const roleResult = useSingleCallResult(contract, role)
  const roleBytes: string | null = roleResult?.result?.[0] ? roleResult?.result?.[0] : null
  const result = useSingleCallResult(
    roleBytes && account ? contract : null,
    'hasRole',
    roleBytes && account ? [roleBytes, account] : []
  )
  return useMemo(() => {
    return result.result?.[0] && account
      ? {
          valid: result.result?.[0],
        }
      : { valid: false }
  }, [result, account])
}
