import { Interface } from '@ethersproject/abi'
import { Pool } from '@netixsol01/v3-sdk'
import { Token } from '@uniswap/sdk-core'
import { abi as UniswapV3PoolABI } from '@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json'
import { useMemo } from 'react'

import { useMultipleContractSingleData, useSingleCallResult } from '../state/multicall/hooks'
import { useTokens } from './Tokens'
import { useEscrowManagerContract } from './useContract'
import { PoolState } from './usePools'
const POOL_INTERFACE = new Interface(UniswapV3PoolABI)

export function useEscrowWhitelistedPoolAddresses(): string[] {
  const escrowManagerContract = useEscrowManagerContract()
  const result = useSingleCallResult(escrowManagerContract ?? null, 'whitelistedLps')
  return result.loading || !result.result?.[0] ? [] : result.result?.[0]
}

export function useEscrowWhitelistedPools(): [PoolState, Pool | null, string][] {
  const poolAddresses = useEscrowWhitelistedPoolAddresses()
  const token0Results = useMultipleContractSingleData(poolAddresses, POOL_INTERFACE, 'token0')
  const token0Loading = token0Results.some((result) => result.loading || !result.result)
  const token0Tokens = useTokens(token0Loading ? [] : token0Results.map((result) => result?.result?.[0]))
  const token1Results = useMultipleContractSingleData(poolAddresses, POOL_INTERFACE, 'token1')
  const token1Loading = token0Results.some((result) => result.loading)
  const token1Tokens = useTokens(token1Loading ? [] : token1Results.map((result) => result?.result?.[0]))
  const fees = useMultipleContractSingleData(poolAddresses, POOL_INTERFACE, 'fee')
  const slot0s = useMultipleContractSingleData(poolAddresses, POOL_INTERFACE, 'slot0')
  const liquidities = useMultipleContractSingleData(poolAddresses, POOL_INTERFACE, 'liquidity')

  return useMemo(() => {
    return liquidities.map((result, index) => {
      const { result: fee, loading: feeLoading, valid: feeValid } = fees[index]
      const { loading: loading0 } = token0Results[index]
      const { loading: loading1 } = token1Results[index]
      const { result: liquidity, loading: liquidityLoading, valid: liquidityValid } = result
      const { result: slot0, loading: slot0Loading, valid: slot0Valid } = slot0s[index]
      if (!slot0Valid || !liquidityValid || !feeValid) return [PoolState.INVALID, null, '']

      if (
        liquidityLoading ||
        loading0 ||
        loading1 ||
        feeLoading ||
        slot0Loading ||
        token0Tokens.length !== liquidities.length ||
        token1Tokens.length !== liquidities.length
      )
        return [PoolState.LOADING, null, '']
      const tokenA: Token = token0Tokens[index]
      const tokenB: Token = token1Tokens[index]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PoolState.INVALID, null, '']
      if (!slot0 || !liquidity || !fee) return [PoolState.NOT_EXISTS, null, '']

      if (!slot0.sqrtPriceX96 || slot0.sqrtPriceX96.eq(0)) return [PoolState.NOT_EXISTS, null, '']
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PoolState.EXISTS,
        new Pool(token0, token1, Number(fee.toString()), slot0.sqrtPriceX96, liquidity.toString(), slot0.tick),
        poolAddresses[index],
      ]
    })
  }, [token0Results, token1Results, token0Tokens, token1Tokens, fees, slot0s, liquidities])
}
