import { Contract } from '@ethersproject/contracts'
import { formatUnits } from 'ethers/lib/utils'
import { useMemo } from 'react'

import { useSingleCallResult } from '../state/multicall/hooks'

export function useUniversalAdminAddress(contract: Contract, functionName: string): string {
  const result = useSingleCallResult(contract ?? null, functionName)
  return useMemo(() => {
    return result?.result?.[0] ? result?.result?.[0] : ''
  }, [result])
}

export function useEscrowFunctionCall(contract: Contract, funcName: string, decimals: number): string {
  const feePercent = useSingleCallResult(contract, funcName).result?.[0]
  return feePercent ? formatUnits(feePercent, decimals) : '0'
}
