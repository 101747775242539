import { Contract } from '@ethersproject/contracts'
import { Trans } from '@lingui/macro'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { isAddress } from 'ethers/lib/utils'
import { Formik, FormikErrors } from 'formik'
import { useUniversalAdminAddress } from 'hooks/useEscrowBuy'
import { useCallback, useContext, useState } from 'react'
import { TransactionType } from 'state/transactions/actions'
import { ThemeContext } from 'styled-components/macro'
import * as Yup from 'yup'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { ThemedText } from '../../theme'
import { shortenAddress } from '../../utils'
import { ButtonPrimary } from '../Button'
import { AutoColumn } from '../Column'
import { ContainerRow, ErrorText, Input, InputContainer, InputPanel, StyledForm } from '../FormComponents'
import { RowBetween } from '../Row'

const ErrorMessage = ({
  name,
  errors,
}: {
  name: 'adminAddress'
  errors: FormikErrors<{
    adminAddress: string
  }>
}) => {
  return <ErrorText>{errors[name]}</ErrorText>
}

interface AdminAddressUpdater {
  title: string
  readFun: string
  writeFun: string
  trxSummary: string
  contract: Contract
}
export default function AdminAddressUpdater({ title, readFun, writeFun, trxSummary, contract }: AdminAddressUpdater) {
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txnHash, setTxnHash] = useState<string | undefined>()
  const theme = useContext(ThemeContext)

  const adminAddress = useUniversalAdminAddress(contract, readFun)
  const addTransaction = useTransactionAdder()

  const tx = async (values: { adminAddress: string }) => {
    const adminAddress = values.adminAddress
    const tx = await contract[writeFun](adminAddress)
    console.log(tx)
    return tx
  }

  async function handleSubmit(values: any, { setSubmitting }: any) {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      const response = await tx({
        adminAddress: values.adminAddress,
      })
      addTransaction(response, {
        summary: `${trxSummary} ${shortenAddress(values.adminAddress)}`,
        type: TransactionType.UNIVERSAL_TRANSACTION_INFO,
      })
      setTxnHash(response.hash)
      setAttemptingTxn(false)
    } catch (error) {
      setShowConfirm(false)
      setAttemptingTxn(false)
      console.error(error)
    }
  }
  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txnHash) {
    }
    setAttemptingTxn(false)
    setTxnHash('')
  }, [txnHash])
  const pendingText = <Trans>{title}</Trans>
  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txnHash ?? ''}
        content={() => null}
        pendingText={pendingText}
      />
      <Formik
        initialValues={{
          adminAddress,
        }}
        validationSchema={Yup.object({
          adminAddress: Yup.string()
            .test('StakingToken', 'Please Enter a Valid Address', (value) => !!(value && isAddress(value)))
            .test(
              'notNullAddress',
              'Please Enter a Valid Address',
              (value) => value !== '0x0000000000000000000000000000000000000000'
            )
            .required('PoolAddress is Required'),
        })}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions)
        }}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ values, errors, handleChange, isSubmitting }) => (
          <>
            <StyledForm>
              <InputPanel>
                <ContainerRow error={!!errors.adminAddress}>
                  <InputContainer>
                    <AutoColumn gap="md">
                      <RowBetween>
                        <ThemedText.Black color={theme.text2} fontWeight={500} fontSize={14}>
                          {title}
                        </ThemedText.Black>
                      </RowBetween>
                      <Input
                        className="recipient-address-input"
                        type="text"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        placeholder={title}
                        error={!!errors.adminAddress}
                        pattern="^(0x[a-fA-F0-9]{40})$"
                        name="adminAddress"
                        onChange={handleChange}
                        value={values.adminAddress}
                      />
                      <ErrorMessage name="adminAddress" errors={errors} />
                    </AutoColumn>
                  </InputContainer>
                </ContainerRow>
              </InputPanel>
              <ButtonPrimary
                marginTop="10px"
                type="submit"
                disabled={isSubmitting || !!errors.adminAddress || values.adminAddress === adminAddress}
              >
                Update
              </ButtonPrimary>
            </StyledForm>
          </>
        )}
      </Formik>
    </>
  )
}
