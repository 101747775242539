import { Contract } from '@ethersproject/contracts'
import { useAuthRoleValidator, useAuthValidator } from 'hooks/useAuthValidator'
import { useActiveWeb3React } from 'hooks/web3'
import styled from 'styled-components/macro'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { useEscrowContract, useEscrowManagerContract, useEscrowSellContract } from '../../hooks/useContract'
import { ExternalLink, ThemedText } from '../../theme'
import { shortenAddress } from '../../utils'
import Row from '../Row'
const StyledWrapper = styled.div`
  position: relative;
`
const StyledMessageWrapper = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
interface AuthValidatorProps {
  children: JSX.Element
  contract: Contract | null
  funName: string
}
export default function AuthValidator({ children, contract, funName }: AuthValidatorProps) {
  const { valid, requiredAddress } = useAuthValidator(funName, contract)
  const { chainId } = useActiveWeb3React()
  return (
    <StyledWrapper>
      {!valid && (
        <StyledMessageWrapper>
          <ThemedText.Link>You are connected with wrong wallet address</ThemedText.Link>
          {requiredAddress && chainId && (
            <Row style={{ justifyContent: 'center' }}>
              <ThemedText.Main color="#fff">Connect with this address &nbsp;</ThemedText.Main>
              <ExternalLink href={getExplorerLink(chainId, requiredAddress, ExplorerDataType.ADDRESS)}>
                {shortenAddress(requiredAddress)}
              </ExternalLink>
            </Row>
          )}
        </StyledMessageWrapper>
      )}
      {children}
    </StyledWrapper>
  )
}

export function EscrowManagerOwnerValidator({ children }: { children: JSX.Element }) {
  const escrowManagerContract = useEscrowManagerContract()

  return (
    <AuthValidator contract={escrowManagerContract} funName="owner">
      {children}
    </AuthValidator>
  )
}

export function EscrowManagerWhitelistRoleValidator({ children }: { children: JSX.Element }) {
  const escrowManagerContract = useEscrowManagerContract()
  const role = 'WHITELIST_LP_ROLE'
  const { valid } = useAuthRoleValidator(role, escrowManagerContract)

  return (
    <StyledWrapper>
      {!valid && (
        <StyledMessageWrapper>
          <ThemedText.Link>
            You need <b>{role}</b> to access this page
          </ThemedText.Link>
        </StyledMessageWrapper>
      )}
      {children}
    </StyledWrapper>
  )
}

export function EscrowBuyOwnerValidator({ children }: { children: JSX.Element }) {
  const escrowContract = useEscrowContract()

  return (
    <AuthValidator contract={escrowContract} funName="owner">
      {children}
    </AuthValidator>
  )
}
export function EscrowSellOwnerValidator({ children }: { children: JSX.Element }) {
  const escrowContract = useEscrowSellContract()

  return (
    <AuthValidator contract={escrowContract} funName="owner">
      {children}
    </AuthValidator>
  )
}
export function EscrowSellAdminValidator({ children }: { children: JSX.Element }) {
  const escrowContract = useEscrowSellContract()

  return (
    <AuthValidator contract={escrowContract} funName="admin">
      {children}
    </AuthValidator>
  )
}
