import { Trans } from '@lingui/macro'
import { FeeAmount, Pool } from '@netixsol01/v3-sdk'
import { Currency } from '@uniswap/sdk-core'
import { EscrowManagerWhitelistRoleValidator } from 'components/AuthValidator'
import { ButtonDropdownLight, ButtonGray, ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import FeeSelector from 'components/FeeSelector'
import { AddWhitelistTabs } from 'components/NavigationTabs'
import Row, { RowBetween } from 'components/Row'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { ExtendedEther } from 'constants/tokens'
import { useEscrowManagerContract } from 'hooks/useContract'
import { PoolState, usePool } from 'hooks/usePools'
import { useEscrowWhitelistedPoolAddresses } from 'hooks/useWhitelistPools'
import { useActiveWeb3React } from 'hooks/web3'
import { PageWrapper, ScrollablePage, Wrapper } from 'pages/AddLiquidity/styled'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'rebass'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { ThemedText } from 'theme'

import { Dots } from '../Pool/styleds'

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1,
}

export default function AddWhitelist() {
  const {
    account,
    chainId,
    // , escrowManager
  } = useActiveWeb3React()

  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txnHash, setTxnHash] = useState<string | undefined>()
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)

  const [currency0, setCurrency0] = useState<Currency | null>(() => (chainId ? ExtendedEther.onChain(chainId) : null))

  const [currency1, setCurrency1] = useState<Currency | null>(null)
  const [fee, setFee] = useState<FeeAmount | undefined>(undefined)
  const history = useHistory()
  const [poolState, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, fee ?? undefined)
  const whitelistedPools = useEscrowWhitelistedPoolAddresses()
  const escrowManagerContract = useEscrowManagerContract()
  const addTransaction = useTransactionAdder()
  const poolAddress: string | null = pool ? Pool.getAddress(pool.token0, pool.token1, pool.fee) : null
  const validPairNoLiquidity: boolean = poolState === PoolState.NOT_EXISTS
  const alreadyWhitelisted = !!whitelistedPools.find(
    (whitelistedPool) => whitelistedPool && poolAddress && whitelistedPool === poolAddress
  )

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency)
      } else {
        setCurrency1(currency)
      }
    },
    [activeField]
  )

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false)
  }, [setShowSearch])
  const handleFeePoolSelect = useCallback((newFeeAmount: FeeAmount) => {
    setFee(newFeeAmount)
  }, [])
  async function whitelistLp() {
    if (!pool || !poolAddress || !escrowManagerContract) return
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      const tx = await escrowManagerContract.whitelistLp(poolAddress)
      addTransaction(tx, {
        summary: 'Whitelisted Pool',
        type: TransactionType.UNIVERSAL_TRANSACTION_INFO,
      })
      setTxnHash(tx.hash)
      setAttemptingTxn(false)
    } catch (error) {
      setShowConfirm(false)
      setAttemptingTxn(false)
      console.error(error)
    }
  }
  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txnHash) {
      history.push('/whitelist')
    }
    setAttemptingTxn(false)
    setTxnHash('')
  }, [txnHash, history])
  const pendingText = <Trans>Whitelisting Pool</Trans>
  const prerequisiteMessage = (
    <LightCard padding="45px 10px">
      <Text textAlign="center">
        {!account ? 'Connect to a wallet to find pools' : 'Select a token to find a pool.'}
      </Text>
    </LightCard>
  )
  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txnHash ?? ''}
        content={() => null}
        pendingText={pendingText}
      />
      <ScrollablePage>
        <PageWrapper wide={false}>
          <AddWhitelistTabs />
          <EscrowManagerWhitelistRoleValidator>
            <Wrapper>
              <AutoColumn gap="lg">
                <RowBetween paddingBottom="20px">
                  <ThemedText.Label>
                    <Trans>Select Pair</Trans>
                  </ThemedText.Label>
                </RowBetween>
                <RowBetween>
                  <ButtonDropdownLight
                    onClick={() => {
                      setShowSearch(true)
                      setActiveField(Fields.TOKEN0)
                    }}
                  >
                    {currency0 ? (
                      <Row>
                        <CurrencyLogo currency={currency0} />
                        <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                          {currency0.symbol}
                        </Text>
                      </Row>
                    ) : (
                      <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                        <Trans>Select a token</Trans>
                      </Text>
                    )}
                  </ButtonDropdownLight>
                  <div style={{ width: '12px' }} />

                  <ButtonDropdownLight
                    onClick={() => {
                      setShowSearch(true)
                      setActiveField(Fields.TOKEN1)
                    }}
                  >
                    {currency1 ? (
                      <Row>
                        <CurrencyLogo currency={currency1} />
                        <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                          {currency1.symbol}
                        </Text>
                      </Row>
                    ) : (
                      <Text fontWeight={500} fontSize={20} marginLeft={'12px'}>
                        <Trans>Select a token</Trans>
                      </Text>
                    )}
                  </ButtonDropdownLight>
                </RowBetween>
                <FeeSelector
                  disabled={!currency0 || !currency1}
                  feeAmount={fee}
                  handleFeePoolSelect={handleFeePoolSelect}
                  currencyA={currency0 ?? undefined}
                  currencyB={currency1 ?? undefined}
                />
                {pool && (
                  <ColumnCenter
                    style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '12px' }}
                  >
                    <Text textAlign="center" fontWeight={500}>
                      Pool Found!
                    </Text>
                  </ColumnCenter>
                )}
                {currency0 && currency1 ? (
                  poolState === PoolState.EXISTS ? (
                    pool && alreadyWhitelisted ? (
                      <ButtonGray disabled={true}>Already Whitelisted</ButtonGray>
                    ) : (
                      <ButtonPrimary disabled={showConfirm} onClick={whitelistLp}>
                        Whitelist
                      </ButtonPrimary>
                    )
                  ) : // hasPosition && pool ? (
                  //   <MinimalPositionCard pool={pool} border="1px solid #CED0D9" />
                  // ) : (
                  //   <LightCard padding="45px 10px">
                  //     <AutoColumn gap="sm" justify="center">
                  //       <Text textAlign="center">You don’t have liquidity in this pool yet.</Text>
                  //       <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                  //         <Text textAlign="center">Add liquidity.</Text>
                  //       </StyledInternalLink>
                  //     </AutoColumn>
                  //   </LightCard>
                  // )
                  validPairNoLiquidity ? (
                    <LightCard padding="45px 10px">
                      <AutoColumn gap="sm" justify="center">
                        <Text textAlign="center">No pool found.</Text>
                        <Text textAlign="center">Create a pool on uniswap first.</Text>
                        {/* <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                  Create pool.
                </StyledInternalLink> */}
                      </AutoColumn>
                    </LightCard>
                  ) : poolState === PoolState.INVALID ? (
                    <LightCard padding="45px 10px">
                      <AutoColumn gap="sm" justify="center">
                        <Text textAlign="center" fontWeight={500}>
                          Invalid pool.
                        </Text>
                      </AutoColumn>
                    </LightCard>
                  ) : poolState === PoolState.LOADING ? (
                    <LightCard padding="45px 10px">
                      <AutoColumn gap="sm" justify="center">
                        <Text textAlign="center">
                          Loading
                          <Dots />
                        </Text>
                      </AutoColumn>
                    </LightCard>
                  ) : null
                ) : (
                  prerequisiteMessage
                )}
              </AutoColumn>
              <CurrencySearchModal
                isOpen={showSearch}
                onCurrencySelect={handleCurrencySelect}
                onDismiss={handleSearchDismiss}
                showCommonBases
                selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
              />
            </Wrapper>
          </EscrowManagerWhitelistRoleValidator>
        </PageWrapper>
      </ScrollablePage>
    </>
  )
}
