import { Trans } from '@lingui/macro'
import { Pool } from '@netixsol01/v3-sdk'
import { Percent } from '@uniswap/sdk-core'
import Badge from 'components/Badge'
import { ButtonSecondary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { RowBetween } from 'components/Row'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { useEscrowManagerContract } from 'hooks/useContract'
import { useCallback, useState } from 'react'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'

const LinkRow = styled.div`
  align-items: center;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  color: ${({ theme }) => theme.text1};
  margin: 8px 0;
  padding: 16px;
  text-decoration: none;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg1};

  &:last-of-type {
    margin: 8px 0 0 0;
  }
  & > div:not(:first-child) {
    text-align: center;
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 12px;
  `};
`
const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`
const DataText = styled.div`
  font-weight: 600;
  font-size: 18px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`
const BadgeText = styled.div`
  font-weight: 500;
  font-size: 14px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`
export default function WhitelistPoolCard({ pool, poolAddress }: { pool: Pool; poolAddress: string }) {
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txnHash, setTxnHash] = useState<string | undefined>()
  const addTransaction = useTransactionAdder()

  const escrowManagerContract = useEscrowManagerContract()

  async function removeWhitelist() {
    if (!escrowManagerContract) return
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      const tx = await escrowManagerContract.removeWhitelistLp(poolAddress)
      addTransaction(tx, {
        summary: 'Removed Whitelisted Pair ',
        type: TransactionType.UNIVERSAL_TRANSACTION_INFO,
      })
      setTxnHash(tx.hash)
      setAttemptingTxn(false)
    } catch (error) {
      setShowConfirm(false)
      setAttemptingTxn(false)
      console.error(error)
    }
  }
  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txnHash) {
    }
    setAttemptingTxn(false)
    setTxnHash('')
  }, [txnHash])
  const pendingText = <Trans>Removing Whitelisted Pool</Trans>
  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txnHash ?? ''}
        content={() => null}
        pendingText={pendingText}
      />
      <LinkRow>
        <RowBetween>
          <PrimaryPositionIdData>
            <DoubleCurrencyLogo currency0={pool.token0} currency1={pool.token1} size={18} margin />
            <DataText>
              &nbsp;{pool?.token0?.symbol}&nbsp;/&nbsp;{pool?.token1?.symbol}
            </DataText>
            &nbsp;
            <Badge>
              <BadgeText>
                <Trans>{new Percent(pool.fee, 1_000_000).toSignificant()}%</Trans>
              </BadgeText>
            </Badge>
          </PrimaryPositionIdData>
          <AutoColumn gap="8px">
            <ButtonSecondary disabled={showConfirm} onClick={removeWhitelist}>
              Remove
            </ButtonSecondary>
          </AutoColumn>
        </RowBetween>
      </LinkRow>
    </>
  )
}
