// import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { Formik, FormikErrors } from 'formik'
import { useCallback, useContext, useState } from 'react'
import { TransactionType } from 'state/transactions/actions'
import styled, { ThemeContext } from 'styled-components/macro'
import * as Yup from 'yup'

import { useEscrowFunctionCall } from '../../hooks/useEscrowBuy'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { ThemedText } from '../../theme'
import { ButtonPrimary } from '../Button'
import { AutoColumn } from '../Column'
import { ContainerRow, ErrorText, InputContainer, InputPanel, StyledForm } from '../FormComponents'
import { Input } from '../NumericalInput'
import { AutoRow, RowBetween } from '../Row'

const ErrorMessage = ({
  name,
  errors,
}: {
  name: 'value'
  errors: FormikErrors<{
    value: string
  }>
}) => {
  return <ErrorText>{errors[name]}</ErrorText>
}

interface AdminValueUpdater {
  title: string
  readFun: string
  writeFun: string
  trxSummary: string
  contract: Contract
  decimals: number
  schema: Yup.AnySchema
  resultTransformer?: (value: string) => string
  transformer?: (value: string) => string
  endEndorse?: JSX.Element
}
const StyledInput = styled(Input)`
  color: ${({ error, theme }) => (error ? theme.red1 : theme.primary1)};
`
export default function NumaricValueUpdater({
  title,
  readFun,
  writeFun,
  trxSummary,
  contract,
  decimals,
  schema,
  transformer,
  resultTransformer,
  endEndorse,
}: AdminValueUpdater) {
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txnHash, setTxnHash] = useState<string | undefined>()
  const theme = useContext(ThemeContext)

  const currentValue = useEscrowFunctionCall(contract, readFun, decimals)
  const transformedValue = resultTransformer ? resultTransformer(currentValue) : currentValue
  const addTransaction = useTransactionAdder()

  const tx = async (values: { value: string }) => {
    const value = transformer ? transformer(values.value) : values.value
    const tx = await contract[writeFun](value)
    console.log(tx)
    return tx
  }
  async function handleSubmit(values: any, { setSubmitting }: any) {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      const response = await tx({
        value: parseUnits(values.value.toString(), decimals).toString(),
      })
      addTransaction(response, {
        summary: trxSummary,
        type: TransactionType.UNIVERSAL_TRANSACTION_INFO,
      })
      setTxnHash(response.hash)
      setAttemptingTxn(false)
    } catch (error) {
      setShowConfirm(false)
      setAttemptingTxn(false)
      console.error(error)
    }
  }
  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txnHash) {
    }
    setAttemptingTxn(false)
    setTxnHash('')
  }, [txnHash])
  const pendingText = <Trans>{title}</Trans>
  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txnHash ?? ''}
        content={() => null}
        pendingText={pendingText}
      />
      <Formik
        initialValues={{
          value: transformedValue,
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions)
        }}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ values, errors, isSubmitting, handleChange }) => (
          <>
            <StyledForm>
              <InputPanel>
                <ContainerRow error={!!errors.value}>
                  <InputContainer>
                    <AutoColumn gap="md">
                      <RowBetween>
                        <ThemedText.Black color={theme.text2} fontWeight={500} fontSize={14}>
                          {title}
                        </ThemedText.Black>
                      </RowBetween>
                      <AutoRow gap={'20px'} style={{ flexWrap: 'nowrap' }}>
                        <StyledInput
                          align="left"
                          placeholder={title}
                          error={!!errors.value}
                          name="value"
                          onUserInput={(value) => handleChange({ target: { name: 'value', value } })}
                          value={values.value}
                        />
                        {endEndorse && endEndorse}
                      </AutoRow>
                      <ErrorMessage name="value" errors={errors} />
                    </AutoColumn>
                  </InputContainer>
                </ContainerRow>
              </InputPanel>
              <ButtonPrimary
                marginTop="10px"
                type="submit"
                disabled={isSubmitting || !!errors.value || Number(values.value) === Number(transformedValue)}
              >
                Update
              </ButtonPrimary>
            </StyledForm>
          </>
        )}
      </Formik>
    </>
  )
}
