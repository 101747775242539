import { Trans } from '@lingui/macro'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { useRequiredAdminToken } from 'hooks/useEscrowSell'
import JSBI from 'jsbi'
import { useCallback, useContext, useState } from 'react'
import { TransactionType } from 'state/transactions/actions'
import styled, { ThemeContext } from 'styled-components/macro'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { EscrowSellAdminValidator } from '../../components/AuthValidator'
import { ButtonConfirmed, ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { ContainerRow, Input, InputContainer, InputPanel } from '../../components/FormComponents'
import Loader from '../../components/Loader'
import { AutoRow, RowBetween } from '../../components/Row'
import { ApprovalState, useWorkingApproveCallback } from '../../hooks/useApproveCallback'
import { useEscrowSellContract } from '../../hooks/useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { ThemedText } from '../../theme'

const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  background: #fff;
  padding: 16px;
  border-radius: 30px;
`
export default function AdminSendTokens() {
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txnHash, setTxnHash] = useState<string | undefined>()
  const theme = useContext(ThemeContext)
  const requiredAdminTokens = useRequiredAdminToken()
  const escrowContract = useEscrowSellContract()
  const addTransaction = useTransactionAdder()
  const [approval, approve] = useWorkingApproveCallback(requiredAdminTokens, escrowContract?.address)

  async function sendTokens() {
    if (!escrowContract || !requiredAdminTokens) throw new Error('Missing dependencies')
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)

      const gasLimit = await escrowContract?.estimateGas.addAdminTokens(requiredAdminTokens.quotient.toString())
      const tx = await escrowContract?.addAdminTokens(requiredAdminTokens.quotient.toString(), {
        gasLimit: calculateGasMargin(gasLimit),
      })
      addTransaction(tx, { summary: 'Added Admin Tokens', type: TransactionType.UNIVERSAL_TRANSACTION_INFO })

      setAttemptingTxn(false)
      setTxnHash(tx.hash)
    } catch (error) {
      setShowConfirm(false)
      setAttemptingTxn(false)
      console.error(error)
    }
  }
  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txnHash) {
    }
    setAttemptingTxn(false)
    setTxnHash('')
  }, [txnHash])
  const pendingText = <Trans>Adding Admin Tokens to Sell Contract</Trans>
  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txnHash ?? ''}
        content={() => null}
        pendingText={pendingText}
      />
      <Container>
        <EscrowSellAdminValidator>
          <AutoColumn gap="30px">
            <ThemedText.LargeHeader textAlign={'center'}>Add Admin Tokens</ThemedText.LargeHeader>
            <ThemedText.Main textAlign={'center'}>
              Rodo team will send the Rodo tokens to Selling contract in order to fulfill the selling / burning
              requests.
            </ThemedText.Main>
            <InputPanel>
              <ContainerRow error={false}>
                <InputContainer>
                  <AutoColumn gap="md">
                    <RowBetween>
                      <ThemedText.Black color={theme.text2} fontWeight={500} fontSize={14}>
                        Add Rodo Tokens
                      </ThemedText.Black>
                    </RowBetween>
                    <AutoRow gap={'20px'} style={{ flexWrap: 'nowrap' }}>
                      <Input
                        className="recipient-address-input"
                        type="number"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        placeholder={'Add rodo tokens'}
                        name="value"
                        disabled={true}
                        value={requiredAdminTokens ? requiredAdminTokens.toSignificant(18) : ''}
                      />
                    </AutoRow>
                  </AutoColumn>
                </InputContainer>
              </ContainerRow>
            </InputPanel>
            {approval === ApprovalState.APPROVED ? (
              <ButtonPrimary
                marginTop="10px"
                type="submit"
                disabled={
                  showConfirm ||
                  !requiredAdminTokens ||
                  (requiredAdminTokens && !requiredAdminTokens.greaterThan(JSBI.BigInt(0)))
                }
                onClick={sendTokens}
              >
                Add Tokens
              </ButtonPrimary>
            ) : (
              <ButtonConfirmed
                onClick={approve}
                disabled={approval !== ApprovalState.NOT_APPROVED}
                altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
              >
                {approval === ApprovalState.PENDING ? (
                  <AutoRow gap="6px" justify="center">
                    Approving <Loader stroke="white" />
                  </AutoRow>
                ) : (
                  'Approve Rodo'
                )}
              </ButtonConfirmed>
            )}
          </AutoColumn>
        </EscrowSellAdminValidator>
      </Container>
    </>
  )
}
