import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { NonfungiblePositionManager, Position } from '@netixsol01/v3-sdk'
import { Currency, CurrencyAmount, Ether, WETH9 } from '@uniswap/sdk-core'
import { ButtonText } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import FormattedCurrencyAmount from 'components/FormattedCurrencyAmount'
import { PositionListItemDetails } from 'components/PositionListItem'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { DEFAULT_AVERAGE_BLOCK_TIME_IN_SECS } from 'constants/chains'
import { RODO_TOKEN } from 'constants/tokens'
import { formatUnits } from 'ethers/lib/utils'
import { useToken } from 'hooks/Tokens'
import { usePool } from 'hooks/usePools'
import useTheme from 'hooks/useTheme'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useV3PositionFees } from 'hooks/useV3PositionFees'
import { useV3PositionsForToken } from 'hooks/useV3Positions'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import React, { useCallback, useMemo, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Text } from 'rebass'
import { TransactionType } from 'state/transactions/actions'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { unwrappedToken } from 'utils/unwrappedToken'

import { EscrowManagerOwnerValidator } from '../../components/AuthValidator'
import { ButtonPrimary } from '../../components/Button'
import { RowBetween, RowFixed, RowFlat } from '../../components/Row'
import { ErrorText } from '../../components/swap/styleds'
import { useEscrowManagerContract, useV3NFTPositionManagerContract } from '../../hooks/useContract'
import useCurrentBlockTimestamp from '../../hooks/useCurrentBlockTimestamp'
import {
  SellInfo,
  SellStatus,
  statusText,
  useAllSells,
  useAvailableTokens,
  useSellPrice,
  useTotalAdminTokens,
} from '../../hooks/useEscrowSell'
import { useBlockNumber } from '../../state/application/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { StyledInternalLink } from '../../theme'
import { getDateFromBlock } from '../../utils/blockstodate'
const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  background: #fff;
  padding: 16px;
  border-radius: 30px;
`
const StyledSelect = styled.select`
  text-transform: uppercase;
  margin-left: 10px;
  padding: 2px;
`
const StyledOption = styled.option`
  text-transform: uppercase;
  padding: 2px;
`
const dateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
}
const filters: string[] = ['all', 'pending', 'executed', 'executeable', 'claimable', 'claimed']
const FILETERS: { [key: string]: (sell: SellInfo) => boolean } = {
  all: (sell: SellInfo) => true,
  pending: (sell: SellInfo) => sell.status === SellStatus.Pending,
  claimable: (sell: SellInfo) => sell.status === SellStatus.Claimeable,
  claimed: (sell: SellInfo) => sell.status === SellStatus.Claimed,
  executed: (sell: SellInfo) => sell.status === SellStatus.Executed,
  executeable: (sell: SellInfo) => sell.status === SellStatus.Executeable,
}

export default function ApproveSells() {
  const [filter, setFilter] = useState<string>('all')

  const { chainId, escrowManager } = useActiveWeb3React()

  const [selectedRows, setSelectedRows] = useState<SellInfo[]>([])
  // const [toggledClearRows, setToggleClearRows] = useState(false)
  const currentBlock = useBlockNumber()
  const currentTimestamp = useCurrentBlockTimestamp()
  const { loading, positions } = useV3PositionsForToken(
    escrowManager,
    chainId ? RODO_TOKEN[chainId] : null,
    chainId ? WETH9[chainId] : null
  )

  const columns: TableColumn<SellInfo>[] = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row: SellInfo) => row.id.toString(),
      },
      {
        name: 'Amount',
        selector: (row: SellInfo) => formatUnits(row.amount, 2),
      },
      {
        name: 'Status',
        selector: (row: SellInfo) => statusText[row.status],
      },
      {
        name: 'Expire Time',
        selector: (row: SellInfo): string => {
          const date = getDateFromBlock(
            row?.endBlock?.toNumber(),
            currentBlock,
            DEFAULT_AVERAGE_BLOCK_TIME_IN_SECS,
            currentTimestamp
          )
          return date ? (date > new Date() ? date.toLocaleString('en', dateFormat) : 'Expired') : ''
        },
      },
    ],
    [currentBlock, currentTimestamp]
  )
  const handleChange = useCallback(({ selectedRows }: any) => {
    setSelectedRows(selectedRows)
  }, [])
  const sells: SellInfo[] = useAllSells()
  const allSells: SellInfo[] = useMemo(() => {
    return sells.filter((sell) => (FILETERS[filter] ? FILETERS[filter](sell) : true))
  }, [sells, filter])

  // useEffect(() => {
  //   setToggleClearRows(!toggledClearRows)
  // }, [allSells])

  const rowDisabledCriteria = useCallback((row: SellInfo): boolean => row.status !== SellStatus.Pending, [])

  function handleChangeFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setFilter(event.target.value)
  }

  const ApproveSellsActionsComponent = useMemo(
    () =>
      loading || !positions ? (
        <div>loading...</div>
      ) : (
        <ApproveSellsActions positions={positions} selectedRows={selectedRows} />
      ),
    [selectedRows, loading, positions]
  )
  const subHeaderComponent = useMemo(() => {
    return (
      <AutoColumn gap={'10px'} style={{ width: '100%' }}>
        {ApproveSellsActionsComponent && ApproveSellsActionsComponent}
        <RowFlat style={{ margin: '10px 0px', alignItems: 'center', marginLeft: 'auto' }}>
          <Text fontWeight={'bold'}>Status:</Text>
          <StyledSelect onChange={handleChangeFilter}>
            {filters.map((filter) => {
              return (
                <StyledOption value={filter} key={filter}>
                  {filter}
                </StyledOption>
              )
            })}
          </StyledSelect>
        </RowFlat>
      </AutoColumn>
    )
  }, [ApproveSellsActionsComponent, selectedRows])
  const MemoTable = useMemo(() => {
    return (
      <DataTable
        title="Approve Sell Requests"
        selectableRows
        pagination
        columns={columns}
        data={allSells}
        onSelectedRowsChange={handleChange}
        selectableRowDisabled={rowDisabledCriteria}
        subHeader
        subHeaderComponent={subHeaderComponent}
        selectableRowsHighlight={true}
        // clearSelectedRows={toggledClearRows}
      />
    )
  }, [handleChange, allSells, columns, rowDisabledCriteria, subHeaderComponent])
  return (
    <Container>
      <EscrowManagerOwnerValidator>{MemoTable}</EscrowManagerOwnerValidator>
    </Container>
  )
}

const StyledSelector = styled.div`
  & > div:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

interface MinimalEscrowPositionCardProps {
  currency0: Currency
  currency1: Currency
  position: Position
  title: string
}
function MinimalEscrowPositionCard({ currency0, currency1, position, title }: MinimalEscrowPositionCardProps) {
  return (
    <LightCard padding="12px 16px" marginTop={'10px'}>
      <AutoColumn gap="md">
        <RowBetween>
          <RowFixed>
            <ThemedText.Black>{title}</ThemedText.Black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <CurrencyLogo currency={currency0} size={'20px'} style={{ marginRight: '0.5rem' }} />
            <ThemedText.Main>{currency0?.symbol} ↗</ThemedText.Main>
          </RowFixed>
          <RowFixed>
            <ThemedText.Main>{position?.amount0.toSignificant(4)}</ThemedText.Main>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <CurrencyLogo currency={currency1} size={'20px'} style={{ marginRight: '0.5rem' }} />
            <ThemedText.Main>{currency1?.symbol} ↗</ThemedText.Main>
          </RowFixed>
          <RowFixed>
            <ThemedText.Main>{position?.amount1.toSignificant(4)}</ThemedText.Main>
          </RowFixed>
        </RowBetween>
      </AutoColumn>
    </LightCard>
  )
}
function ApproveSellsActions({
  selectedRows,
  positions,
}: {
  selectedRows: SellInfo[]

  positions: PositionDetails[]
}) {
  const [selectedTokenId, setSelectedTokenId] = useState<BigNumber | null>(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txnHash, setTxnHash] = useState<string | undefined>()
  const { chainId, library } = useActiveWeb3React()
  const theme = useTheme()

  const price = useSellPrice()
  const rodo = chainId ? RODO_TOKEN[chainId] : null
  const eth = chainId ? Ether.onChain(chainId) : null
  const positionManager = useV3NFTPositionManagerContract()
  const escrowManagerContract = useEscrowManagerContract()
  const { availableEth, availableRodo } = useAvailableTokens()
  const totalAdminTokens = useTotalAdminTokens()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline() // custom from users settings

  const totalRodoAmount: BigNumber = selectedRows.reduce((sum, row) => sum.add(row.amount), BigNumber.from(0))
  const requiredEth: CurrencyAmount<Currency> | null =
    price && eth
      ? CurrencyAmount.fromRawAmount(eth, getRequiredAmount(availableEth, totalRodoAmount.mul(price).div(100))._hex)
      : null
  const requiredLpRodo: CurrencyAmount<Currency> | null = rodo
    ? CurrencyAmount.fromRawAmount(rodo, getRequiredAmount(availableRodo, totalRodoAmount.mul(80).div(100))._hex)
    : null
  const requiredAminRodo = rodo ? CurrencyAmount.fromRawAmount(rodo, totalRodoAmount.mul(20).div(100)._hex) : null

  const selectedPositionDetails = positions.find((p) => (!selectedTokenId ? false : p.tokenId.eq(selectedTokenId)))

  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
  } = selectedPositionDetails ?? {}
  const token0 = useToken(token0Address)
  const token1 = useToken(token1Address)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined

  // construct Position from details returned
  const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)

  const position = useMemo(() => {
    if (pool && liquidity && tickLower && tickUpper) {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])
  const [feeValue0, feeValue1] = useV3PositionFees(pool ?? undefined, selectedPositionDetails?.tokenId, false)

  const amounts: { amount0: CurrencyAmount<Currency>; amount1: CurrencyAmount<Currency> } | null = useMemo(() => {
    if (!requiredEth || !requiredLpRodo || !token0 || !token1 || !eth || !rodo) {
      return null
    }
    let amount0 = requiredLpRodo,
      amount1 = requiredEth
    ;[amount0, amount1] = token0.equals(rodo) ? [amount0, amount1] : [amount1, amount0]
    return {
      amount0,
      amount1,
    }
  }, [requiredEth, requiredLpRodo, rodo, eth, token0, token1])

  const requiredLiquidity: JSBI | null = useMemo(() => {
    if (!position || !amounts) return null

    return amounts
      ? position.requiredLiquidity(JSBI.BigInt(amounts.amount0.quotient), JSBI.BigInt(amounts.amount1.quotient))
      : null
  }, [position, amounts])

  const requiredPosition = useMemo(() => {
    if (pool && requiredLiquidity && tickLower && tickUpper) {
      return new Position({ pool, liquidity: requiredLiquidity, tickLower, tickUpper })
    }
    return undefined
  }, [pool && requiredLiquidity && tickLower && tickUpper])
  const outOfRange: boolean =
    pool && tickLower && tickUpper ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false

  const isValid =
    !!liquidity && !!requiredLiquidity ? JSBI.greaterThan(JSBI.BigInt(liquidity.toString()), requiredLiquidity) : false
  const isValidAdminTokens = requiredAminRodo && totalAdminTokens && !requiredAminRodo.greaterThan(totalAdminTokens)
  // console.log(
  //   selectedTokenId,
  //   position,
  //   requiredLiquidity && requiredLiquidity.toString(),
  //   isValid,
  //   liquidity?.toString(),
  //   !!amounts?.amount0?.currency?.isNative,
  //   !!amounts?.amount1?.currency?.isNative,
  //   requiredEth?.toSignificant(18),
  //   requiredLpRodo?.toSignificant(18),
  //   'positions tokenid'
  // )

  function clearAll() {
    setSelectedTokenId(null)
  }

  function removeLiquidityAndApprove() {
    setAttemptingTxn(true)

    try {
      if (
        !escrowManagerContract ||
        !rodo ||
        selectedRows.length <= 0 ||
        !isValid ||
        !requiredLiquidity ||
        !selectedPositionDetails ||
        !position ||
        !deadline ||
        !amounts ||
        !positionManager ||
        !library ||
        !feeValue0 ||
        !feeValue1
      )
        throw new Error('missing dependencies')

      const { calldata, value } = NonfungiblePositionManager.removeAndApproveCallParameters(position, {
        tokenId: selectedPositionDetails.tokenId.toString(),
        liquidity: requiredLiquidity,
        deadline: deadline.toString(),
        collectOptions: {
          expectedCurrencyOwed0: feeValue0,
          expectedCurrencyOwed1: feeValue1,
          recipient: escrowManagerContract.address,
          source: positionManager.address,
        },
        approveOptions: {
          amount0Min: amounts.amount0,
          amount1Min: amounts.amount1,
          sellIds: selectedRows.map((row) => row.id),
        },
      })
      const txn = {
        to: escrowManagerContract.address,
        data: calldata,
        value,
      }
      library
        .getSigner()
        .estimateGas(txn)
        .then((estimate) => {
          const newTxn = {
            ...txn,
            gasLimit: calculateGasMargin(estimate),
          }

          return library
            .getSigner()
            .sendTransaction(newTxn)
            .then((response: TransactionResponse) => {
              setTxnHash(response.hash)
              setAttemptingTxn(false)
              addTransaction(response, {
                type: TransactionType.UNIVERSAL_TRANSACTION_INFO,
                summary: 'Approved Sell Requests',
              })
            })
        })
        .catch((error) => {
          setAttemptingTxn(false)

          console.error(error)
        })
    } catch (error) {
      setShowConfirm(false)
      console.error(error)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txnHash) {
      clearAll()
    }
    setAttemptingTxn(false)
    setTxnHash('')
  }, [clearAll, txnHash])
  const liquidityValue0 = requiredPosition?.amount0,
    liquidityValue1 = requiredPosition?.amount1
  const pendingText = (
    <Trans>
      Removing {liquidityValue0?.toSignificant(6)} {liquidityValue0?.currency?.symbol} and{' '}
      {liquidityValue1?.toSignificant(6)} {liquidityValue1?.currency?.symbol}
    </Trans>
  )

  function modalHeader() {
    return (
      <AutoColumn gap={'sm'} style={{ padding: '16px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={500}>
            <Trans>Pooled {liquidityValue0?.currency?.symbol}:</Trans>
          </Text>
          <RowFixed>
            <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
              {liquidityValue0 && <FormattedCurrencyAmount currencyAmount={liquidityValue0} />}
            </Text>
            <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={liquidityValue0?.currency} />
          </RowFixed>
        </RowBetween>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={500}>
            <Trans>Pooled {liquidityValue1?.currency?.symbol}:</Trans>
          </Text>
          <RowFixed>
            <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
              {liquidityValue1 && <FormattedCurrencyAmount currencyAmount={liquidityValue1} />}
            </Text>
            <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={liquidityValue1?.currency} />
          </RowFixed>
        </RowBetween>
        {feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) ? (
          <>
            <ThemedText.Italic fontSize={12} color={theme.text2} textAlign="left" padding={'8px 0 0 0'}>
              <Trans>You will also collect fees earned from this position.</Trans>
            </ThemedText.Italic>
            <RowBetween>
              <Text fontSize={16} fontWeight={500}>
                <Trans>{feeValue0?.currency?.symbol} Fees Earned:</Trans>
              </Text>
              <RowFixed>
                <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                  {feeValue0 && <FormattedCurrencyAmount currencyAmount={feeValue0} />}
                </Text>
                <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={feeValue0?.currency} />
              </RowFixed>
            </RowBetween>
            <RowBetween>
              <Text fontSize={16} fontWeight={500}>
                <Trans>{feeValue1?.currency?.symbol} Fees Earned:</Trans>
              </Text>
              <RowFixed>
                <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                  {feeValue1 && <FormattedCurrencyAmount currencyAmount={feeValue1} />}
                </Text>
                <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={feeValue1?.currency} />
              </RowFixed>
            </RowBetween>
          </>
        ) : null}
        <ButtonPrimary mt="16px" onClick={removeLiquidityAndApprove}>
          <Trans>Remove and Approve</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }
  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txnHash ?? ''}
        content={() => (
          <ConfirmationModalContent
            title={<Trans>Remove Liquidity</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
          />
        )}
        pendingText={pendingText}
      />
      {selectedRows.length > 0 && (
        <AutoColumn>
          {selectedTokenId ? (
            <ButtonText onClick={clearAll} margin="0 15px 0 auto">
              <ThemedText.Blue fontSize="12px">
                <Trans>Clear Select</Trans>
              </ThemedText.Blue>
            </ButtonText>
          ) : (
            <ThemedText.MediumHeader>Select a position</ThemedText.MediumHeader>
          )}
          {positions
            .filter((p) => (!selectedTokenId ? true : p.tokenId.eq(selectedTokenId)))
            .map((p) => {
              return (
                <StyledSelector
                  key={p.tokenId.toString()}
                  onClick={() => {
                    setSelectedTokenId(p.tokenId)
                  }}
                >
                  <PositionListItemDetails positionDetails={p} />
                </StyledSelector>
              )
            })}
          {position && currency0 && currency1 && (
            <MinimalEscrowPositionCard
              currency0={currency0}
              currency1={currency1}
              position={position}
              title="Your Position"
            />
          )}
          {requiredPosition && currency0 && currency1 && (
            <MinimalEscrowPositionCard
              currency0={currency0}
              currency1={currency1}
              position={requiredPosition}
              title="Required Position"
            />
          )}
          {requiredPosition && (
            <RowBetween marginTop={'20px'} marginBottom="20px">
              <AutoColumn>
                {isValid ? (
                  <AutoColumn gap={'10px'}>
                    {outOfRange ? (
                      <ErrorText severity={4}>Cannot use out of range position.</ErrorText>
                    ) : (
                      <Text>Approve Selected Requests</Text>
                    )}
                    {!isValidAdminTokens && (
                      <ErrorText severity={4}>
                        Low admin tokens{' '}
                        <StyledInternalLink id="import-pool-link" to={'/adminsendtokens'}>
                          Add Admin Tokens
                        </StyledInternalLink>
                      </ErrorText>
                    )}
                  </AutoColumn>
                ) : (
                  <RowBetween>
                    <ThemedText.Main>Insufficient liquidity for this trade.</ThemedText.Main>
                  </RowBetween>
                )}
              </AutoColumn>
              <ButtonPrimary
                maxWidth={'250px'}
                disabled={!isValid || showConfirm || !isValidAdminTokens || outOfRange}
                onClick={() => setShowConfirm(true)}
              >
                Approve
              </ButtonPrimary>
            </RowBetween>
          )}
        </AutoColumn>
      )}
    </>
  )
}
function getRequiredAmount(available: BigNumber, required: BigNumber): BigNumber {
  return available.gt(required) ? BigNumber.from('0') : required.sub(available)
}
