import { BigNumber } from '@ethersproject/bignumber'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { RODO_TOKEN } from 'constants/tokens'
import { getAddress } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { useETHBalances, useTokenBalance } from 'state/wallet/hooks'

import { useSingleCallResult } from '../state/multicall/hooks'
import { useEscrowSellContract } from './useContract'
import { useActiveWeb3React } from './web3'

export enum SellStatus {
  Pending,
  Executed,
  Claimed,
  Executeable,
  Claimeable,
}

export const statusText = ['Pending', 'Executed', 'Claimed', 'Executeable', 'Claimeable']

export type SellInfo = {
  id: number
  amount: BigNumber
  feeAmount: BigNumber
  startBlock: BigNumber
  endBlock: BigNumber
  status: SellStatus
  user: string
}

export function useAllSells(): SellInfo[] {
  const escrowSellContract = useEscrowSellContract()
  const allSellsResult = useSingleCallResult(escrowSellContract, 'allSells')
  return useMemo(() => {
    return allSellsResult.loading || !allSellsResult.result?.[0]
      ? []
      : allSellsResult.result?.[0].map((row: any) => ({ ...row, id: Number(row.id) }))
  }, [JSON.stringify(allSellsResult)])
}

export function useSellPrice(): BigNumber | null {
  const escrowSellContract = useEscrowSellContract()
  const price = useSingleCallResult(escrowSellContract, 'price')
  return price.loading || !price.result?.[0] ? null : price.result?.[0]
}

export function useAvailableTokens(): { availableEth: BigNumber; availableRodo: BigNumber } {
  const { chainId } = useActiveWeb3React()
  const escrowSellContract = useEscrowSellContract()
  const rodo = chainId ? RODO_TOKEN[chainId] : undefined
  const rodoTokenBalance = useTokenBalance(escrowSellContract?.address, rodo)
  const ethBalance = useETHBalances(escrowSellContract ? [escrowSellContract.address] : [])?.[
    escrowSellContract ? getAddress(escrowSellContract.address) : '' ?? ''
  ]
  const ethResult = useSingleCallResult(escrowSellContract, 'totalEth')
  const lpResult = useSingleCallResult(escrowSellContract, 'totalLpTokens')
  const sellqueueResult = useSingleCallResult(escrowSellContract, 'totalSellQueueTokens')
  const totalAdminResult = useSingleCallResult(escrowSellContract, 'totalAdminTokens')
  return useMemo(() => {
    return rodoTokenBalance &&
      ethBalance &&
      sellqueueResult &&
      totalAdminResult &&
      ethResult?.result?.[0] &&
      lpResult?.result?.[0] &&
      sellqueueResult?.result?.[0] &&
      totalAdminResult?.result?.[0]
      ? {
          availableEth: BigNumber.from(ethBalance.quotient.toString()).sub(ethResult?.result?.[0]),
          availableRodo: BigNumber.from(rodoTokenBalance.quotient.toString())
            .sub(lpResult?.result?.[0])
            .sub(sellqueueResult?.result?.[0])
            .sub(totalAdminResult?.result?.[0]),
        }
      : { availableEth: BigNumber.from('0'), availableRodo: BigNumber.from('0') }
  }, [rodoTokenBalance, ethBalance, ethResult, lpResult, totalAdminResult, sellqueueResult])
}

export function useTotalAdminTokens(): CurrencyAmount<Token> | undefined {
  const { chainId } = useActiveWeb3React()
  const escrowContract = useEscrowSellContract()
  // const requiredToken = useSingleCallResult(escrowContract, 'getTotalRequiredAdminTokens').result?.[0]
  const currentAdminTokens = useSingleCallResult(escrowContract, 'totalAdminTokens').result?.[0]
  const rodo = chainId && RODO_TOKEN[chainId]
  return useMemo(() => {
    return rodo
      ? currentAdminTokens
        ? CurrencyAmount.fromRawAmount(rodo, currentAdminTokens)
        : CurrencyAmount.fromRawAmount(rodo, '0')
      : undefined
  }, [rodo, currentAdminTokens])
}
export function useRequiredAdminToken(): CurrencyAmount<Token> | undefined {
  const { chainId } = useActiveWeb3React()
  const escrowContract = useEscrowSellContract()
  const requiredToken = useSingleCallResult(escrowContract, 'getTotalRequiredAdminTokens').result?.[0]
  const currentAdminTokens = useSingleCallResult(escrowContract, 'totalAdminTokens').result?.[0]
  const rodo = chainId && RODO_TOKEN[chainId]
  return rodo
    ? requiredToken && currentAdminTokens && requiredToken > currentAdminTokens
      ? CurrencyAmount.fromRawAmount(rodo, requiredToken)
      : CurrencyAmount.fromRawAmount(rodo, '0')
    : undefined
}
