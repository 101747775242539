import { Trans } from '@lingui/macro'
import { Pool } from '@netixsol01/v3-sdk'
import { EscrowManagerWhitelistRoleValidator } from 'components/AuthValidator'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { SwapPoolTabs } from 'components/NavigationTabs'
import { RowBetween, RowFixed } from 'components/Row'
import WhitelistPoolCard from 'components/WhitelistPoolCard'
import { useEscrowWhitelistedPools } from 'hooks/useWhitelistPools'
import { LoadingRows } from 'pages/Pool/styleds'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'

const PageWrapper = styled(AutoColumn)`
  max-width: 870px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.text2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`
const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: row-reverse;
  `};
`
const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  padding: 6px 8px;
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};
`
const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.bg0};
  padding: 8px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`
const NoLiquidity = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`
export default function Whitelist() {
  const theme = useContext(ThemeContext)

  const whitelistedPools = useEscrowWhitelistedPools()
  const loadingWhitelistedPools = !whitelistedPools || whitelistedPools?.some(([, pool]) => !pool)
  const allWhitelistedPools = whitelistedPools.map<[Pool | null, string]>(([, pool, poolAddress]) => [
    pool,
    poolAddress,
  ])

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <EscrowManagerWhitelistRoleValidator>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
                <ThemedText.Body fontSize={'20px'}>
                  <Trans>Whitelist Overview</Trans>
                </ThemedText.Body>
                <ButtonRow>
                  <ResponsiveButtonPrimary id="whitelist-pool-button" as={Link} to="/whitelist/add">
                    + <Trans> Whitelist Pool</Trans>
                  </ResponsiveButtonPrimary>
                </ButtonRow>
              </TitleRow>
              <MainContentWrapper>
                {loadingWhitelistedPools ? (
                  <LoadingRows>
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                  </LoadingRows>
                ) : allWhitelistedPools && allWhitelistedPools.length > 0 ? (
                  allWhitelistedPools.map(([pool, poolAddress]) => {
                    return (
                      pool &&
                      poolAddress && <WhitelistPoolCard poolAddress={poolAddress} pool={pool} key={poolAddress} />
                    )
                  })
                ) : (
                  <NoLiquidity>
                    <ThemedText.Body color={theme.text3} textAlign="center">
                      <div>
                        <Trans>Your whitelisted pools will apear here.</Trans>
                      </div>
                    </ThemedText.Body>
                  </NoLiquidity>
                )}
              </MainContentWrapper>
            </AutoColumn>
          </AutoColumn>
        </EscrowManagerWhitelistRoleValidator>
      </PageWrapper>
    </>
  )
}
